<template>
  <div id="collapse-C" class="collapse show">
    <div ref="specialties-container" class="card-body position-relative">
      <h5>Professional Specialties</h5>
      <div class="row">
        <div class="col">
          <div v-if="specialties.length == 0" class="text-center py-1">
            <span class="text-muted"> No specialties </span>
          </div>
          <ul v-else class="list-group">
            <li
              v-for="specialty in specialties"
              :key="specialty.id"
              class="list-group-item"
            >
              {{ specialty.name }}
              {{ specialty.description ? ' - ' + specialty.description : '' }}
            </li>
          </ul>
          <tbl-page-seln
            v-model="pgNum"
            class="mt-3"
            :total-pages="totalPages"
            @updated="refresh"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import TblPageSeln from '../../shared/components/TblPageSeln.vue';

export default {
  components: {
    TblPageSeln,
  },
  setup() {
    const userApi = inject('api').userApi();
    const loading = inject('$loading');
    const route = useRoute();

    onMounted(() => {
      refresh();
    });

    const specialties = ref([]);
    const pgNum = ref(1);
    const pgSz = ref(10);
    const totalPages = ref(0);
    const specialtiesContainer = ref(null);

    const refresh = async () => {
      const loader = loading.show({
        container: specialtiesContainer.value,
        isFullPage: false,
      });
      const response = await userApi.getPublicSpecialties(route.params.uid);
      specialties.value = response.data.data;
      totalPages.value = response.data.totalPages;
      loader.hide();
    };

    return {
      specialties,
      'specialties-container': specialtiesContainer,
      refresh,
      pgNum,
      pgSz,
      totalPages,
    };
  },
};
</script>
