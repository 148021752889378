<template>
  <div id="collapse-A" class="collapse show">
    <div ref="profile-container" class="card-body position-relative">
      <div class="row align-items-start">
        <div class="col-lg-6 col-xl-5 mb-4">
          <div class="row g-2">
            <div class="col-12 d-flex align-items-end">
              <img :src="profilePicSrc" class="w-25" alt="..." />
            </div>
            <div class="col-12">
              <h4>
                Name: {{ profile.namePrefix }} {{ profile.firstName }}
                {{ profile.middleInitial }} {{ profile.lastName }}
                {{
                  profile.certifications ? ', ' + profile.certifications : ''
                }}
              </h4>
            </div>
            <div class="mt-3 col-8 border border-primary">
              Bio: {{ profile.biography || 'None' }}
            </div>
          </div>
        </div>

        <div class="col mb-4">
          <div class="row g-2">
            <div class="col-12">
              <p>Phone: {{ profile.phone || 'None' }}</p>
            </div>
            <div class="col-12">
              <p>Address: {{ profile.address || 'None' }}</p>
            </div>
            <div class="col-12">
              <p>City: {{ profile.city || 'None' }}</p>
            </div>
            <div class="col-12">
              <!-- Hardcoded as Ohio for phase 1 dev -->
              <p>State: Ohio</p>
            </div>
            <div class="col-12">
              <p>Zip Code: {{ profile.postalCode || 'None' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const userApi = inject('api').userApi();
    const loading = inject('$loading');
    const route = useRoute();

    const profileContainer = ref(null);
    const profile = ref({});

    onMounted(async () => {
      const loader = loading.show({
        container: profileContainer.value,
        isFullPage: false,
      });
      const response = await userApi.getPublicProfileInfo(route.params.uid);
      profile.value = response.data;
      loader.hide();
    });

    const profilePicSrc = computed(() => {
      return `${process.env.VUE_APP_SERVER_URL}/api/user/pic/${route.params.uid}`;
    });

    return {
      profilePicSrc,
      'profile-container': profileContainer,
      profile,
    };
  },
};
</script>
