<template>
  <div ref="prof-profile-view" class="container">
    <!-- Page content here -->
    <div ref="profile-container" class="row position-relative">
      <!-- tabbed nav for desktop hidden on mobile view -->
      <div class="col responsive-tabs">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: currTab == 'prof-profile' }"
              style="cursor: pointer"
              @click="setTab('prof-profile')"
              >Profile</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ active: currTab == 'prof-specialties' }"
              style="cursor: pointer"
              @click="setTab('prof-specialties')"
              >Specialties</a
            >
          </li>
        </ul>

        <!-- Accordion items - headers hidden on desktop view-->
        <div id="content" class="tab-content mb-4">
          <!-- tab one My Profile -->
          <div
            id="pane-A"
            class="card tab-pane fade"
            :class="{ 'active show': currTab == 'prof-profile' }"
          >
            <div class="card-header">
              <h5 class="mb-0">
                <a
                  :class="{ collapsed: currTab != 'prof-profile' }"
                  style="cursor: pointer"
                  @click="setTab('prof-profile')"
                  >My Profile</a
                >
              </h5>
            </div>
            <prof-profile v-if="currTab == 'prof-profile'" />
          </div>
          <!-- tab two Specialties -->
          <div
            id="pane-C"
            class="card tab-pane fade"
            :class="{ 'active show': currTab == 'prof-specialties' }"
          >
            <div class="card-header">
              <h5 class="mb-0">
                <a
                  :class="{ collapsed: currTab != 'prof-specialties' }"
                  style="cursor: pointer"
                  @click="setTab('prof-specialties')"
                  >Specialties</a
                >
              </h5>
            </div>
            <prof-specialties v-if="currTab == 'prof-specialties'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import ProfProfile from './ProfProfile.vue';
import ProfSpecialties from './ProfSpecialties.vue';

export default {
  components: {
    ProfProfile,
    ProfSpecialties,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const profProfileView = ref(null);

    onMounted(() => {
      if (route.query && route.query.tab) {
        currTab.value = route.query.tab;
      } else {
        currTab.value = 'prof-profile';
      }

      window.scrollTo(0, profProfileView.value.offsetTop);
    });

    const currTab = ref('prof-profile');
    const setTab = async (tabName) => {
      currTab.value = tabName;

      router.replace({ query: { tab: tabName } });
    };

    return {
      currTab,
      setTab,
      'prof-profile-view': profProfileView,
    };
  },
};
</script>
